import type {
  AdditionalProductEvaluation,
  AdditionalProductOpening,
  AdditionalProductRelation,
  ProductEvaluation,
  ProductsOpening,
} from "@acrevis/curo-api-client";
import { isEqual } from "lodash";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { curoApiClient } from "@/api/services/curo";
import { virtualProductEvaluations } from "@/core/virtualProductEvaluations";
import { useErrorService, useLogger } from "@/services";
import { isContextAwareError } from "@/services/error.service";
import { usePersonalDataStore } from "@/store/process/steps/personalData";
import type { VirtualProductEvaluation } from "@/types/VirtualProductEvaluation";

export type SelectedAdditionalProductGroupedByAdditionalProduct = Pick<AdditionalProductEvaluation, "id" | "name"> &
  Pick<AdditionalProductOpening, "id"> & { clients: number[] } & Pick<AdditionalProductRelation, "mandatory">;

export type SelectedAdditionalProductGroupedByProduct = {
  product: ProductEvaluation;
  additionalProducts: (Pick<AdditionalProductEvaluation, "id" | "name"> &
    Pick<AdditionalProductOpening, "id" | "product"> & { clients: number[] } & Pick<
      AdditionalProductRelation,
      "mandatory"
    >)[];
};

type ProductIncludingAdditionalProducts = Omit<ProductEvaluation, "additionalProductRelations"> & {
  additionalProducts: (AdditionalProductEvaluation & AdditionalProductRelation)[];
};

export const useProductSelectionStore = defineStore(
  "productSelection",
  () => {
    const finished = ref(false);
    const products = ref<(ProductEvaluation | VirtualProductEvaluation)[]>([]);
    const additionalProducts = ref<AdditionalProductEvaluation[]>([]);
    const orders = ref<ProductsOpening>({
      products: [],
      additionalProducts: [],
    });

    const logger = useLogger();
    const errorService = useErrorService();

    const setFinished = () => {
      finished.value = true;
    };

    const unsetFinished = () => {
      finished.value = false;
    };

    const evaluateProducts = async (payload: {
      evaluateProductsData: {
        clientMainType: "ClientDouble" | "ClientSingle";
        dateOfBirth: string;
        inEducation?: boolean;
        options?: never;
      };
    }) => {
      logger.debug(`Evaluating products with input`, payload.evaluateProductsData);
      try {
        const response = await curoApiClient.evaluateProductController.evaluateProductsUsingGet(
          payload.evaluateProductsData.dateOfBirth,
          payload.evaluateProductsData.clientMainType,
          payload.evaluateProductsData.inEducation ?? false,
        ); // TODO: Remove Casting
        products.value = [...response.productEvaluations, ...virtualProductEvaluations];
        additionalProducts.value = response.additionalProducts;
      } catch (error) {
        if (error instanceof Error || (error instanceof Error && isContextAwareError(error))) {
          errorService.throwError(error);
        }
      }
    };

    const completedProductSelectionDataCollection = computed(() => {
      return orders.value.products.length > 0;
    });

    const completedAdditionProductSelectionDataCollection = computed(() => {
      const productsWithMandatoryAdditionalProducts = selectedProducts.value.filter((product) =>
        product.additionalProductRelations.some(
          (additionalProductRelation) => additionalProductRelation.mandatory === true,
        ),
      );

      if (!productsWithMandatoryAdditionalProducts) return true;

      const fulfilledAllMandatoryAdditionalProducts = productsWithMandatoryAdditionalProducts.every(
        (productWithMandatoryAdditionalProducts) => {
          return productWithMandatoryAdditionalProducts.additionalProductRelations
            .filter((additionalProductRelation) => additionalProductRelation.mandatory === true)
            .every((mandatoryAdditionalProductRelation) => {
              return orders.value.additionalProducts.some(
                (additionalProductOrder) => additionalProductOrder.id === mandatoryAdditionalProductRelation.id,
              );
            });
        },
      );

      return fulfilledAllMandatoryAdditionalProducts;
    });

    const additionalProductsPerPerson = computed(() => {
      return additionalProducts.value
        .filter((additionalProduct) => additionalProduct.perPerson === true && additionalProduct.perProduct === false)
        .map((additionalProduct) => {
          const mandatory = selectedProducts.value.some((product) =>
            product.additionalProductRelations.find(
              (additionalProductRelation) =>
                additionalProductRelation.id === additionalProduct.id && additionalProductRelation.mandatory === true,
            ),
          );

          return {
            mandatory,
            ...additionalProduct,
          };
        });
    });

    const additionalProductsPerProduct = computed(() => {
      return additionalProducts.value.filter(
        (additionalProduct) => additionalProduct.perPerson === false && additionalProduct.perProduct === true,
      );
    });

    const additionalProductsPerPersonPerProduct = computed(() => {
      return additionalProducts.value.filter(
        (additionalProduct) => additionalProduct.perPerson === true && additionalProduct.perProduct === true,
      );
    });

    const selectedProducts = computed<ProductEvaluation[]>(() => {
      return orders.value.products.reduce((acc, current) => {
        const originalProduct = products.value.find((prod) => prod.id === current.id);
        if (originalProduct) {
          return [...acc, originalProduct];
        }
        return acc;
      }, [] as ProductEvaluation[]);
    });

    const selectedProductsWithAdditionsPerPersonPerProduct = computed(() => {
      return selectedProductsWithAdditions.value.map(({ additionalProducts, ...selectedProductWithAdditions }) => {
        const additionalProductsPerPersonPerProduct = additionalProducts.filter(
          (additionalProduct) => additionalProduct.perPerson === true && additionalProduct.perProduct === true,
        );

        return {
          ...selectedProductWithAdditions,
          additionalProducts: additionalProductsPerPersonPerProduct,
        };
      });
    });

    const selectedAdditionalProducts = computed<(AdditionalProductOpening & AdditionalProductEvaluation)[]>(() => {
      if (orders.value.additionalProducts.length < 1) return [];

      return orders.value.additionalProducts.map((selectedAdditionalProduct) => {
        const additionalProduct = additionalProducts.value.find(
          (additionalProduct) => selectedAdditionalProduct.id === additionalProduct.id,
        );

        if (!selectedAdditionalProduct || !additionalProduct)
          throw new Error("Error while looking for additionalProducts");

        return {
          ...selectedAdditionalProduct,
          ...additionalProduct,
        };
      });
    });

    const selectedAdditionalProductsPerPerson = computed<
      (AdditionalProductOpening & AdditionalProductEvaluation & AdditionalProductRelation)[]
    >(() => {
      const selectedPerPersonProducts = orders.value.additionalProducts.filter((additionalProductOrder) => {
        return additionalProducts.value.find(
          (additionalProduct) =>
            additionalProduct.id === additionalProductOrder.id &&
            additionalProduct.perPerson === true &&
            additionalProduct.perProduct === false,
        );
      });

      const hasSelectedPerPersonProducts = selectedPerPersonProducts.length > 0;

      if (!hasSelectedPerPersonProducts) return [];

      return selectedPerPersonProducts.map((selectedAdditionalProduct) => {
        const additionalProduct = additionalProductsPerPerson.value.find(
          (additionalProduct) => selectedAdditionalProduct.id === additionalProduct.id,
        );

        if (!selectedAdditionalProduct || !additionalProduct)
          throw new Error("Error while looking for additionalProducts");

        return {
          ...selectedAdditionalProduct,
          ...additionalProduct,
        };
      });
    });

    const selectedAdditionalProductsPerPersonGroupedByAdditionalProduct = computed(() => {
      return selectedAdditionalProductsPerPerson.value.reduce(
        (acc: SelectedAdditionalProductGroupedByAdditionalProduct[], value) => {
          if (value.client === null || value.client === undefined) throw new Error("Client number is missing.");

          if (acc.length === 0) {
            return [
              {
                id: value.id,
                name: value.name,
                clients: [value.client],
                mandatory: value.mandatory,
              },
            ];
          }

          const existingAdditionalProduct = acc.find((item) => item.id === value.id);

          if (existingAdditionalProduct) {
            existingAdditionalProduct.clients.push(value.client);
          } else {
            acc.push({
              id: value.id,
              name: value.name,
              clients: [value.client],
              mandatory: value.mandatory,
            });
          }

          return acc;
        },
        [],
      );
    });

    const selectedAdditionalProductsPerProduct = computed<(AdditionalProductOpening & AdditionalProductEvaluation)[]>(
      () => {
        const selectedPerProductProducts = orders.value.additionalProducts.filter((additionalProductOrder) => {
          return additionalProducts.value.find(
            (additionalProduct) =>
              additionalProduct.id === additionalProductOrder.id &&
              additionalProduct.perPerson === false &&
              additionalProduct.perProduct === true,
          );
        });

        const hasSelectedPerProductProducts = selectedPerProductProducts.length > 0;

        if (!hasSelectedPerProductProducts) return [];

        return selectedPerProductProducts.map((selectedAdditionalProduct) => {
          const additionalProduct = additionalProductsPerProduct.value.find(
            (additionalProduct) => selectedAdditionalProduct.id === additionalProduct.id,
          );
          if (!selectedAdditionalProduct || !additionalProduct)
            throw new Error("Error while looking for additionalProducts");

          return {
            ...selectedAdditionalProduct,
            ...additionalProduct,
          };
        });
      },
    );

    const selectedAdditionalProductsPerPersonPerProduct = computed<
      (AdditionalProductOpening & AdditionalProductEvaluation)[]
    >(() => {
      const selectedPerProductPerPersonProducts = orders.value.additionalProducts.filter((additionalProductOrder) => {
        return additionalProducts.value.find(
          (additionalProduct) =>
            additionalProduct.id === additionalProductOrder.id &&
            additionalProduct.perPerson === true &&
            additionalProduct.perProduct === true,
        );
      });

      const hasSelectedPerProductPerPersonProducts = selectedPerProductPerPersonProducts.length > 0;

      if (!hasSelectedPerProductPerPersonProducts) return [];

      return selectedPerProductPerPersonProducts.map((selectedAdditionalProduct) => {
        const additionalProduct = additionalProductsPerPersonPerProduct.value.find(
          (additionalProduct) => selectedAdditionalProduct.id === additionalProduct.id,
        );

        if (!selectedAdditionalProduct || !additionalProduct)
          throw new Error("Error while looking for additionalProducts");

        return {
          ...selectedAdditionalProduct,
          ...additionalProduct,
        };
      });
    });

    const selectedAdditionalProductsPerProductPerPersonGroupedByProduct = computed(
      (): SelectedAdditionalProductGroupedByProduct[] => {
        return selectedProducts.value.map((product, index): SelectedAdditionalProductGroupedByProduct => {
          return {
            product,
            additionalProducts: selectedAdditionalProductsPerPersonPerProduct.value
              .filter((selectedAdditionalProduct) => selectedAdditionalProduct.product === index)
              .reduce(
                (acc: SelectedAdditionalProductGroupedByProduct["additionalProducts"], additionalProductValue) => {
                  if (additionalProductValue.client === null || additionalProductValue.client === undefined)
                    throw new Error("Client number is missing.");
                  const relation = product.additionalProductRelations.find(
                    (additionalProduct) => additionalProduct.id === additionalProductValue.id,
                  );
                  const mandatory = !!relation?.mandatory;

                  if (acc.length === 0) {
                    return [
                      {
                        product: additionalProductValue.product,
                        id: additionalProductValue.id,
                        name: additionalProductValue.name,
                        clients: [additionalProductValue.client],
                        mandatory,
                      },
                    ];
                  }

                  const existingAdditionalProduct = acc.find(
                    (item) => item.product === additionalProductValue.product && item.id === additionalProductValue.id,
                  );

                  if (existingAdditionalProduct) {
                    existingAdditionalProduct.clients.push(additionalProductValue.client);
                  } else {
                    acc.push({
                      product: additionalProductValue.product,
                      id: additionalProductValue.id,
                      name: additionalProductValue.name,
                      clients: [additionalProductValue.client],
                      mandatory,
                    });
                  }

                  return acc;
                },
                [],
              ),
          };
        });
      },
    );

    const selectedProductsWithAdditions = computed<ProductIncludingAdditionalProducts[]>(() => {
      return selectedProducts.value.map(({ additionalProductRelations, ...product }) => {
        const additionalProductsForProduct = additionalProductRelations.map((relation) => {
          const additionalProduct = additionalProducts.value.find(
            (additionalProduct) => additionalProduct.id === relation.id,
          );

          if (!additionalProduct || !relation) throw new Error("Did not find additional product");

          return {
            ...additionalProduct,
            ...relation,
          };
        });

        return {
          ...product,
          additionalProducts: additionalProductsForProduct,
        };
      });
    });

    const ordersSortedByLeadClient = computed<ProductsOpening>(() => {
      const { leadClientIndex } = usePersonalDataStore();
      return {
        products: orders.value.products,
        additionalProducts: orders.value.additionalProducts.map((AdditionalProductOpening) => {
          return {
            id: AdditionalProductOpening.id,
            ...(AdditionalProductOpening.product !== undefined ? { product: AdditionalProductOpening.product } : {}),
            client:
              leadClientIndex === null || leadClientIndex === 0
                ? AdditionalProductOpening.client
                : AdditionalProductOpening.client === 0
                  ? 1
                  : 0,
          };
        }),
      };
    });

    const selectProduct = (id: ProductEvaluation["id"]) => {
      if (orders.value.products.includes({ id })) return;

      orders.value.products = [...orders.value.products, { id }];
    };

    const resetSelection = () => {
      orders.value.products = [];
      orders.value.additionalProducts = [];
      finished.value = false;
    };

    const unselectProduct = (id: ProductEvaluation["id"]) => {
      const productIndex = orders.value.products.findIndex((product) => product.id === id);

      if (productIndex === -1) return;

      orders.value.products.splice(productIndex, 1);

      if (orders.value.products.length === 0) {
        orders.value.additionalProducts = [];
        return;
      }

      orders.value.additionalProducts = orders.value.additionalProducts
        .filter((additionalProduct) => additionalProduct.product !== productIndex)
        .map((additionalProduct) => {
          if (additionalProduct.product && additionalProduct.product > productIndex) {
            return {
              ...additionalProduct,
              product: additionalProduct.product - 1,
            };
          }
          return additionalProduct;
        });
    };

    const selectAdditionalProduct = (additionalProduct: AdditionalProductOpening) => {
      (Object.keys(additionalProduct) as (keyof AdditionalProductOpening)[]).forEach((key) =>
        additionalProduct[key] === undefined ? delete additionalProduct[key] : "",
      );

      const alreadyHasAdditionalProduct = orders.value.additionalProducts.some((additionalProductOrder) => {
        return isEqual(additionalProductOrder, additionalProduct);
      });

      if (alreadyHasAdditionalProduct) return;

      orders.value.additionalProducts = [...orders.value.additionalProducts, additionalProduct];
    };

    const unselectAdditionalProduct = (additionalProduct: AdditionalProductOpening) => {
      (Object.keys(additionalProduct) as (keyof AdditionalProductOpening)[]).forEach((key) =>
        additionalProduct[key] === undefined ? delete additionalProduct[key] : undefined,
      );

      orders.value.additionalProducts = orders.value.additionalProducts.filter(
        (additionalProductOrder) => !isEqual(additionalProductOrder, additionalProduct),
      );
    };

    return {
      finished,
      products,
      additionalProducts,
      orders,
      setFinished,
      unsetFinished,
      evaluateProducts,
      completedProductSelectionDataCollection,
      completedAdditionProductSelectionDataCollection,
      additionalProductsPerPerson,
      additionalProductsPerProduct,
      additionalProductsPerPersonPerProduct,
      selectedProducts,
      selectedProductsWithAdditionsPerPersonPerProduct,
      selectedAdditionalProducts,
      selectedAdditionalProductsPerPerson,
      selectedAdditionalProductsPerPersonGroupedByAdditionalProduct,
      selectedAdditionalProductsPerProduct,
      selectedAdditionalProductsPerPersonPerProduct,
      selectedAdditionalProductsPerProductPerPersonGroupedByProduct,
      selectedProductsWithAdditions,
      ordersSortedByLeadClient,
      selectProduct,
      resetSelection,
      unselectProduct,
      selectAdditionalProduct,
      unselectAdditionalProduct,
    };
  },
  {
    persist: true,
  },
);
