import { CuroApi } from "@acrevis/curo-api-client";

import { servicesConfig } from "@/config";

export type RelationshipType = 50 | 53 | 51; // 50 = marriage / 53 = registered partnership / 51 = other

export enum ContactMethodType {
  PHONE_PRIVATE = "TP1",
  PHONE_BUSINESS = "TG2",
  PHONE_HOLIDAY_FLAT = "1002",
  FAX_BUSINESS = "FG1",
  MOBILE_PRIVATE = "TN1",
  MOBILE_BUSINESS = "1001",
  MAIL_BUSINESS = "EMG",
  MAIL_PRIVATE = "EMP",
  WEBPAGE = "INET",
}

export const curoApiClient = new CuroApi({
  BASE: servicesConfig.curo.api,
});

export const selfOnboardingControllerApiClient = curoApiClient.midealSelfOnboardingController;
